import React from 'react';

import { ClassName, GADataType } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import {
  MaterialSelectButtonMaterial,
  MaterialSelectButtonMaterialsSet,
  MaterialSelectButtonOnSelect
} from './MaterialSelectButton.types';
import {
  FetchMaterialsSetsCacheKeys,
  UpdateMaterialsSetCacheAction
} from '../../../../materialsSets/materialsSetsTypes';
import {
  OnSelectedMaterialsSidebarOpenAction,
  OnSelectedMaterialsSidebarCloseAction
} from '../../../materialsTypes';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { useMaterialSelectButton } from './hooks/useMaterialSelectButton';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';
import { words } from '../../../../../locales/keys';

interface MaterialSelectButtonProps {
  material: MaterialSelectButtonMaterial;
  materialsSet: MaterialSelectButtonMaterialsSet | null;
  materialsSetCacheKeys?: FetchMaterialsSetsCacheKeys;
  onSelectedMaterialsSidebarOpen?: OnSelectedMaterialsSidebarOpenAction;
  onSelectedMaterialsSidebarClose?: OnSelectedMaterialsSidebarCloseAction;
  updateMaterialsSetCache: UpdateMaterialsSetCacheAction<MaterialSelectButtonMaterialsSet>;
  onSelect?: MaterialSelectButtonOnSelect;
  selectedClassName: ClassName;
  unselectedClassName: ClassName;
  iconClassName?: ClassName;
}

function MaterialSelectButton({
  material,
  materialsSet,
  materialsSetCacheKeys,
  onSelectedMaterialsSidebarOpen,
  onSelectedMaterialsSidebarClose,
  updateMaterialsSetCache,
  onSelect,
  selectedClassName,
  unselectedClassName,
  iconClassName,
  dataGa
}: MaterialSelectButtonProps & GADataType) {
  const { isSelected, handleToggleSelected } = useMaterialSelectButton({
    material,
    materialsSet,
    onSelectedMaterialsSidebarOpen,
    onSelectedMaterialsSidebarClose,
    updateMaterialsSetCache,
    onSelect,
    materialsSetCacheKeys
  });

  return (
    <PureTooltipIconButtonHelper
      dataGa={dataGa || 'materials-material-select-button'}
      className={isSelected ? selectedClassName : unselectedClassName}
      i18nText={isSelected ? words.selected : words.select}
      icon={isSelected ? IconsEnum.CHECK : null}
      iconClassName={iconClassName || 'h-4 w-4'}
      tooltipI18nText={words.selectToAddToTheProject}
      tooltipPlacement={TooltipPlacement.TOP}
      onClick={handleToggleSelected}
    />
  );
}

export default MaterialSelectButton;

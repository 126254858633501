import React, { useCallback } from 'react';

import { ClassName, GADataType, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { useToastNotification } from '../../../../../common/hooks/useToastNotification';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { copyToClipboard } from '../../../../../utils/copyToClipboard';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { MaterialsCopyLinkButtonMaterial } from './MaterialsCopyLinkButton.type';

import { words } from '../../../../../locales/keys';

import { MaterialPath } from '../../../MaterialPath';

interface MaterialsCopyLinkButtonProps {
  material: MaterialsCopyLinkButtonMaterial;
  icon?: IconsEnum;
  i18nText?: I18nText;
  className?: ClassName;
  iconClassName?: ClassName;
  tooltipI18nText?: I18nText;
  disabled?: boolean;
  onClick?: () => void;
}

function MaterialsCopyLinkButton({
  material,
  className,
  disabled,
  i18nText,
  icon,
  iconClassName,
  onClick,
  tooltipI18nText,
  dataGa
}: MaterialsCopyLinkButtonProps & GADataType) {
  const { showToastI18nNotification } = useToastNotification({
    appearance: 'success',
    i18nMessage: words.linkCopied
  });

  const handleCopyLinkToClipboard = useCallback<() => void>(() => {
    copyToClipboard(
      `${window.location.origin}${MaterialPath.show(material.nanoId)}`
    );
    onClick?.();
    showToastI18nNotification();
  }, [onClick, material.nanoId, showToastI18nNotification]);

  return (
    <PureTooltipIconButtonHelper
      dataGa={dataGa || 'materials-material-copy-link-button'}
      tooltipPlacement={TooltipPlacement.LEFT}
      onClick={handleCopyLinkToClipboard}
      disabled={disabled}
      className={className}
      icon={icon}
      iconClassName={iconClassName}
      i18nText={i18nText}
      tooltipI18nText={tooltipI18nText}
    />
  );
}

export default MaterialsCopyLinkButton;
